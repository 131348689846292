import React from "react";
import "animate.css";
import "./SectionTitle.scss";
import { Divider } from "@mui/material";

type SectionTitleProps = {
  title: string;
}

function SectionTitle({ title }: SectionTitleProps) {
  return (
    <div className="sectiontitle">
      <span className="sectiontitle__name">
      {title}
      </span>
      <Divider />
    </div>
  );
}

export default SectionTitle;
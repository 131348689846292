import React from "react";
import "animate.css";
import "./Navbar.scss";

function Navbar() {
  return (
    <div className="navbar">
      <span className="navbar__name">
        MICHAEL
        <br />
        FREDERIC
      </span>
      <span className="open-work">BETA</span>
    </div>

  );
}

export default Navbar;

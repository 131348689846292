import "animate.css";
import "./App.scss";
import Navbar from "./components/navbar/Navbar";
import LeftPane from "./components/left pane/LeftPane";
import RightPane from "./components/right pane/RightPane";
import BottomPane from "./components/bottom pane/BottomPane";
import Footer from "./components/footer/Footer";
import { ReactComponent as GitHubLogo } from "./materials/icons/SVG/github.svg";
import { ReactComponent as LocationIcon } from "./materials/icons/SVG/location.svg";
import { ReactComponent as PhoneIcon } from "./materials/icons/SVG/phone.svg";
import { ReactComponent as FrMailIcon } from "./materials/icons/SVG/mail.svg";

export enum ProjectType {
  PERSONAL = "Personal",
  WORK = "Work",
  SENIOR = "Senior",
}

export interface Contact {
  text: string;
  icon: any;
  link?: string;
}
export interface Education {
  school: string;
  degree: string;
  major: string;
  gpa: number;
  deansList: string[];
  honors: string;
}

export interface Experience {
  company: string;
  position: string;
  location: string;
  description?: string[];
  startDate: Date;
  endDate?: Date;
}

export interface Project {
  name: string;
  type: ProjectType;
  description?: string;
  link?: string;
  image?: string;
}

export default function App() {
  // Left Pane
  const skills: string[] = [
    "Angular/React",
    "Node.JS",
    "TypeScript",
    "JavaScript",
    "Python",
    "Web developement",
    "Agile",
    "AWS",
    "OpenCV",
    "Linux Kernel",
    "Embedded",
  ];
  const contact: Contact[] = [
    {
      icon: FrMailIcon,
      text: "Email",
      link: "mailto:Michael.C.Frederic@gmail.com",
    },
    { icon: PhoneIcon, text: "Call/Text", link: "tel:+1(805)291-6467" },
    {
      icon: LocationIcon,
      text: "Phoenix, AZ",
      link: "https://www.google.com/maps/place/Phoenix,+AZ/",
    },
    { icon: GitHubLogo, text: "mfreder7", link: "https://github.com/mfreder7" },
  ];

  // Right Pane
  const keyPoints: string[] = [
    "Software Engineer with experience in full-stack development.",
    "Experienced in object-oriented development, testing and debugging.",
    "Able to learn and adapt to new technologies as they emerge.",
    "Comfortable with fast paced team environments and self-directed settings.",
  ];
  const education: Education = {
    degree: "Bachelor of Science",
    major: "Software Engineering",
    school: "Arizona State University",
    gpa: 3.61,
    deansList: ["Spring 2020", "Fall 2020", "Spring 2021"],
    honors: "Magna Cum Laude",
  };
  const relevantExperience: Experience = {
    company: "Xpress Technologies",
    position: "Frontend Engineer",
    location: "Scottsdale, AZ",
    description: [
      "Takes part in sprints and contribute to building a production ready responsive web application.",
      "Utilizes a full stack of development tools to create robust responsive components utilizing complex libraries and methodologies.",
      "Participates in large scale projects and refactoring of the code base.",
      "Acts as a mentor to new hires and brings them up to speed with technical skills, knowledge, and company culture.",
    ],
    startDate: new Date(2020, 8, 0),
  };

  // Bottom Pane
  const projects: Project[] = [
    {
      type: ProjectType.PERSONAL,
      name: "Fitshare.",
      link: "https://fitshare.me/",
    },

    {
      type: ProjectType.PERSONAL,
      name: "Image Collection Generator",
      link: "https://nft-collection-generator.herokuapp.com/",
    },
    {
      type: ProjectType.SENIOR,
      name: "Proximity Sensor Gesture Detection",
      link: "https://github.com/jacarpe7/project_28",
    },
  ];

  return (
    <div className="App">
      <Navbar />
      <div className="mid-panes">
        <LeftPane skills={skills} contact={contact} />
        <RightPane
          keyPoints={keyPoints}
          education={education}
          experience={relevantExperience}
        />
      </div>
      <BottomPane projects={projects} />
      <Footer />
    </div>
  );
}

import React from "react";
import "animate.css";
import "./BottomPane.scss";
import SectionTitle from "../../materials/section-title/SectionTitle";
import { Project } from "../../App";
import { Link } from "@mui/material";

interface BottomPaneProps {
  projects: Project[];
}

function BottomPane({ projects }: BottomPaneProps) {
  return (
    <div className="bottompane">
      <SectionTitle title="Projects" />
      <ul>
        {projects.map((project, index) => {
          if (!project?.link) {
            return (
              <li key={project.name + index}>
                {project.name}, {project.type} -{" "}
                {project?.description ?? "No description"}
              </li>
            );
          } else {
            return (
              <li key={project.name + index}>
                {project.name}, {project.type} -{" "}
                <Link color="inherit" target="_blank" href={project.link}>
                  {project.link}
                </Link>
              </li>
            );
          }
        })}
      </ul>
    </div>
  );
}

export default BottomPane;

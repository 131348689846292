import React from "react";
import "animate.css";
import "./LeftPane.scss";
import SectionTitle from "../../materials/section-title/SectionTitle";
import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  SvgIcon,
} from "@mui/material";
import { Contact } from "../../App";
import MediaQuery from "react-responsive";

interface SkillsList {
  skills: string[];
  contact: Contact[];
}

function LeftPane({ skills, contact }: SkillsList) {

  // Create a list of contacts
  const contacts = contact.map((contact, index) => {
    return false;
  });


  // Wobble animation state array
  const [wobble, setWobble] = React.useState(contacts as boolean[]);

  // Change wobble animation state in array.
  const startWobble = (index: number) => {
    return (event: React.MouseEvent) => {

      if(wobble[index]) {
        stopWobble(index);
      }
      
      // Create new array with new state.
      const newWobble = [...wobble];

      // Toggle state of index.
      newWobble[index] = !newWobble[index];

      // Set new state.
      setWobble(newWobble);
    };
  };

    // Change wobble animation state in array.
    const stopWobble = (index: number) => {
      return (event: React.MouseEvent) => {
        
        // Create new array with new state.
        const newWobble = [...wobble];
  
        // Toggle state of index.
        newWobble[index] = !newWobble[index];
  
        // Set new state.
        setWobble(newWobble);
      };
    };

  return (
    <div className="leftpane">
      <SectionTitle title="Contact" />
      <List dense={true}>
        {contact.map((contact, index) => {
          
          return (
            <ListItem alignItems="flex-start" key={index} disablePadding>
                              <MediaQuery minWidth={450}>

              <ListItemButton
                onMouseEnter={startWobble(index)}
                target={contact?.link?.includes("tel:") ? "" : "_blank"}
                href={contact?.link ?? ""}
              >
                <ListItemIcon>
                  <SvgIcon
                    onAnimationEnd={stopWobble(index)}
                    className={wobble[index] ? "animation-wobbler" : ""}
                    inheritViewBox={true}
                    component={contact.icon}
                  ></SvgIcon>
                </ListItemIcon>
                
                  <ListItemText
                    className="button-text"
                    primary={contact.text}
                  />
                  
              </ListItemButton>
              </MediaQuery>
              <MediaQuery maxWidth={449}>
              <ListItemButton
              className="mobile-icon-list"
                target={contact?.link?.includes("tel:") ? "" : "_blank"}
                href={contact?.link ?? ""}
              >
              <SvgIcon

                    onAnimationEnd={stopWobble(index)}
                    className="animation-wobbler"
                    inheritViewBox={true}
                    component={contact.icon}
                  ></SvgIcon></ListItemButton>
              </MediaQuery>
            </ListItem>
          );
        })}
      </List>

      <div className="title-section">
        <SectionTitle title="Skills" />
        <ul>
          {skills.map((skill, index) => (
            <li key={skill + index} value={skill}>
              {skill}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default LeftPane;

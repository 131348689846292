import React from "react";
import "animate.css";
import "./RightPane.scss";
import SectionTitle from "../../materials/section-title/SectionTitle";
import { Education, Experience } from "../../App";

interface RightPaneProps {
  keyPoints: string[];
  education: Education;
  experience: Experience;
}

function RightPane({ keyPoints, education, experience }: RightPaneProps) {
  const startDate = experience.startDate.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
  });
  const endDate = experience?.endDate?.toLocaleDateString(undefined, {
    year: "numeric",
    month: "2-digit",
  });

  return (
    <div className="rightpane">
      <div>
        <SectionTitle title="About" />
        <ul>
          {keyPoints.map((keyPoint, index) => (
            <li key={keyPoint + index} value={keyPoint}>
              {keyPoint}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <SectionTitle title="Education" />
        <div className="text-section">
          <div>
            {education.degree} in {education.major}
          </div>
          <div>{education.school}</div>
          <div>GPA: {education.gpa}</div>
          <div>Deans List: {education.deansList.join(", ")}</div>
        </div>
      </div>
      <div>
        <SectionTitle title="Relevant Experience" />
        <div className="text-section-w-list">
          <div>{experience.company + ", " + experience.location}</div>
          <div>{experience.position}</div>
          <span>
            {startDate} - {endDate ?? "current"}
          </span>
        </div>
        <ul>
          {experience?.description?.map((keyPoint, index) => (
            <li key={keyPoint + index} value={keyPoint}>
              {keyPoint}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export default RightPane;
